<template>
  <div class="row mt-2">
    <div class="col-3 size-label">
      Размер: <span class="text-uppercase">{{ info.size }}</span>
    </div>
    <div class="col-3 size-label">
      Цена:
      <span class="item-price">
         <b class="text-uppercase font-medium-3">{{ info.prices.user_discount_price }}</b> грн
      </span>
    </div>
    <div class="col-3">
      <input
          type="number"
          class="form-control"
          min="1" step="1"
          :max="info.qty"
          v-model="qty"
          :readonly="info.qty === '1'"
      >
      <div class="row">
        <div class="col-12 stock-line text-center">
          <span>В наличии: {{ info.qty }}</span>
        </div>
      </div>
    </div>
    <div class="col-3">
      <button
          @click="addToCart"
          class="btn btn-success"
          :disabled="parseInt(qty, 10) > parseInt(info.qty, 10)"
      >
        В корзину
      </button>
    </div>

  </div>
</template>

<script>
export default {
  name: 'ProductStockForm',
  props: ['info', 'price'],
  data() {
    return {
      qty: 1,
    };
  },
  methods: {
    async addToCart() {
      try {
        await this.$api.cart.add(this.info.id, { qty: this.qty, price: this.info.prices.user_discount_price });
        this.$bvToast.toast('Товар добавлен в корзину', {
          title: 'Выполнено успешно',
          variant: 'success',
          solid: true,
        });
        this.$emit('added');
      } catch (e) {
        this.$bvToast.toast(e.response.data.message, {
          title: 'Ошибка при добавлении товара в корзину',
          variant: 'danger',
          solid: true,
        });
      }
    },
  },
};
</script>

<style scoped>
.size-label {
  padding-top: 10px;
}

.stock-line {
  font-size: 11px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ececec;
}
</style>
