<template>
  <div class="app-content content" :class="[{'show-overlay': $store.state.app.shallShowOverlay}]">
    <div class="content-overlay"/>
    <div class="header-navbar-shadow"/>
    <div class="content-wrapper">
      <section class="app-ecommerce-details">
        <div class="card" v-if="product">
          <div class="card-body">
            <div class="row my-2">
              <div class="col-12 col-md-5 mb-2 mb-md-0">
                <div class="row d-flex align-items-center justify-content-center">
                  <div class="d-flex align-items-center justify-content-center">
                    <vue-magnifier
                        v-if="product.big_main_image || product.draft_main_image"
                        :src="mainImg"
                        :src-large="mainImg"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col mt-1" v-for="(img, index) in product.big_images" :key="index">
                    <b-img
                        :alt="`${product.name}-${product.id}`"
                        fluid
                        @click="setMainImage(img)"
                        class="product-img small"
                        :src="`/img/product/${img}`"
                    />
                  </div>
                </div>

              </div>
              <div class="col-12 col-md-7">
                <h4>{{ product.name }} {{ product.brand_name }}</h4>
                <div class="ecommerce-details-price d-flex flex-wrap mt-1" v-if="product.user_price">
                  <b class="mr-1 item-price font-large-1">{{ product.user_price }} грн</b>
                </div>
                <b-card-text>{{ product.description }}</b-card-text>

                <ul class="product-features list-unstyled mb-3">
                  <li>
                    <feather-icon size=18 icon="TagIcon"/>&nbsp;Артикул: &nbsp;<span>{{ product.sku }}</span></li>
                  <li>
                    <feather-icon size=18 icon="CircleIcon"/>&nbsp;Цвет: &nbsp;<span>{{ product.color_name }}</span>
                  </li>
                  <li>
                    <feather-icon size=18 icon="GlobeIcon"/>&nbsp;Страна производства:
                    &nbsp;<span>{{ product.country_name }}</span>
                  </li>
                  <li>
                    <feather-icon size=18 icon="LayersIcon"/>
                    Состав:&nbsp;<span>{{ product.full_materials }}</span>
                  </li>
                  <li v-if="product.retail_price">
                    <feather-icon size=18 icon="DollarSignIcon"/>
                    Розничная цена:&nbsp;<span class="item-price"><b class="font-medium-4">{{
                      product.retail_price
                    }}</b> грн</span>
                  </li>
                  <li class="mt-2">
                    <button
                        class="btn btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0 btn-outline-primary"
                        @click="downloadImages"
                    >
                      <feather-icon icon="DownloadIcon" class="mr-50"/>
                      <span>Скачать фото</span>
                    </button>
                  </li>
                </ul>
                <h4 v-if="product.specifications.length">Варианты в наличии:</h4>
                <hr class="mb-3" />
                <div class="col-12 variant-block d-inline-block mb-1 p-2"
                     v-for="specification in product.specifications"
                     :key="specification.id"
                >
                  <div class="row mb-1">
                    <div class="col mb-1" v-if="specification.size_name">
                      Размер:
                      <b>
                        <span class="text-uppercase font-medium-4">{{specification.size_name }}</span>
                      </b>
                      <span v-if="specification.size_model">
                            {{
                          specification.size_model.name !== specification.size
                              ? '(' + specification.size_model.name + ')'
                              : ''
                        }}
                      </span>
                      <span v-if="specification.size_model">
                              <feather-icon
                                  :id="specification.id + 'popover'"
                                  icon="InfoIcon"
                                  size="16"
                                  class="align-text-top"
                              />
                              <b-popover custom-class="size-popover" :target="specification.id + 'popover'"
                                         triggers="hover" placement="top">
                                <template #title>Соответствие размерам</template>
                                  <div class="row text-nowrap mb-1">
                                    <div class="col">EU: {{ specification.size_model.eu }}</div>
                                    <div class="col">IT: {{ specification.size_model.it }}</div>
                                    <div class="col-auto">UA: {{ specification.size_model.ua }}</div>
                                  </div>
                                  <div class="row text-nowrap mb-1">
                                    <div class="col">DE: {{ specification.size_model.de }}</div>
                                    <div class="col">UK: {{ specification.size_model.uk }}</div>
                                    <div class="col">US: {{ specification.size_model.us }}</div>
                                  </div>
                                  <div class="row text-nowrap">
                                    <div class="col">CA: {{ specification.size_model.ca }}</div>
                                    <div class="col">China: {{ specification.size_model.china }}</div>
                                  </div>
                              </b-popover>
                            </span>
                    </div>
                    <div class="col text-right">ID: {{ specification.id }}</div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <ul class="product-features list-unstyled">
                        <li v-if="specification.prices.user_discount_price < specification.prices.user_price">
                          <span
                              :class="[
                                  'font-weight-bold text-uppercase font-medium-2',
                                  specification.prices.user_discount_percent > 50 ? 'danger' : 'warning'
                                  ]"
                          >
                             Скидка {{ specification.prices.user_discount_percent.toFixed(0) }}%
                           </span>
                        </li>
                        <li v-if="specification.prices.user_discount_price < specification.prices.user_price">
                          Старая цена: <b>{{ specification.prices.user_price }}грн</b>
                        </li>
                        <li>
                          <feather-icon size=18 icon="BoxIcon"/> Мерки: {{ specification.full_measurements }}
                        </li>
                        <li>
                          <feather-icon size=18 icon="TagIcon"/> Состояние: новый {{ specification.condition_name }}
                        </li>
                        <li>
                          <feather-icon size=18 icon="PackageIcon"/> Вес: {{ specification.weight }} грамм
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
                          @click="showModal(product, product.id)"
                      >
                        <feather-icon
                            icon="ShoppingCartIcon"
                            class="mr-50"
                        />
                        <span>В корзину</span>
                      </b-button>
                    </div>
                    <div class="col align-self-center">
                      <div class="row">
                        <div class="col-auto align-self-center">Оптовая цена:</div>
                        <div class="col">
                          <span class="item-price">
                            <b class="text-uppercase font-large-1">{{ specification.prices.user_discount_price }}</b>грн
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col align-self-center text-right">
                      В наличии: {{ specification.qty }} шт
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr class="mb-3"/>
          <div class="item-features mb-3">
            <div class="row text-center">
              <div class="col-12 col-md-4 mb-4 mb-md-0">
                <div class="w-75 mx-auto">
                  <feather-icon icon="AwardIcon" size="35"/>
                  <h4 class="mt-2 mb-1">100% Оригинальная продукция</h4>
                  <p class="card-text">Мы продаем только оригинальные брендовые товары. Мы не продаем подделки.</p>
                </div>
              </div>
              <div class="col-12 col-md-4 mb-4 mb-md-0">
                <div class="w-75 mx-auto">
                  <feather-icon icon="ClockIcon" size="35"/>
                  <h4 class="mt-2 mb-1">180 дней для возврата или обмена</h4>
                  <p class="card-text">
                    Вы можете обменять или вернуть товар без уценки в течение 30 дней с момента получения.
                  </p>
                </div>
              </div>
              <div class="col-12 col-md-4 mb-4 mb-md-0">
                <div class="w-75 mx-auto">
                  <feather-icon icon="ShieldIcon" size="35"/>
                  <h4 class="mt-2 mb-1">1 год гарантии</h4>
                  <p class="card-text">
                    Мы даем год предпродажной гарантии на все наши товары. Если вам не понравится, мы вернем вам деньги.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <b-modal
            ref="add-cart"
            size="lg"
            centered
            v-if="product"
            hide-footer
        >
          <template slot="modal-title">
            <h3>Добавление в корзину:</h3>
          </template>
          <b-card-text>
            <div class="row">
              <div class="col-12">
                {{ product.name }} {{ product.brand_name }}
              </div>
              <div class="col-12 mt-1">
                Артикул <b>{{ product.sku }}</b>
              </div>
            </div>
            <hr/>
            <ProductStockForm
                v-for="specification in product.specifications"
                :info="specification"
                :key="specification.id"
                @added="getProduct"
            />
          </b-card-text>
        </b-modal>
      </section>
    </div>
  </div>
</template>

<script>
import {
  BImg, BCardText, BButton, BPopover,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import VueMagnifier from '@/views/components/VueMagnifier.vue';
import ProductStockForm from '@/views/components/ProductStockForm.vue';

export default {
  name: 'ProductDetails',
  directives: {
    Ripple,
  },
  components: {
    BImg,
    BCardText,
    BButton,
    BPopover,
    ProductStockForm,
    VueMagnifier,
  },
  data() {
    return {
      product: null,
      mainImg: null,
      selectedProduct: null,
    };
  },
  async mounted() {
    await this.getProduct();
  },
  methods: {
    setMainImage(image) {
      this.mainImg = `/img/product/${image}`;
    },
    async downloadImages() {
      try {
        await this.$api.products.downloadImages(this.product.id, `img_${this.product.id}.zip`);
      } catch (e) {
        // console.log(e.message);
      }
    },
    async getProduct() {
      this.product = (await this.$api.products.getOffer(this.$route.params.id)).data;
      this.mainImg = `/img/product/${this.product.big_main_image || this.product.draft_main_image}`;
    },
    async showModal() {
      if (this.product.specifications.length === 1 && this.product.specifications[0].qty === '1') {
        try {
          await this.$api.cart.add(this.product.specifications[0].id, {
            qty: 1, price: this.product.user_price,
          });
          this.$bvToast.toast('Товар добавлен в корзину', {
            title: 'Выполнено успешно',
            variant: 'success',
            solid: true,
          });
          await this.getProduct();
        } catch (e) {
          // console.log(e);
        }
      } else {
        this.$refs['add-cart'].show();
      }
    },
  },
};
</script>

<style lang="scss">
@import '~@core/scss/base/core/colors/palette-variables.scss';
@import "~@core/scss/base/pages/app-ecommerce-details.scss";

.product-img.main {
  width: 310px !important;
}

.product-img.small {
  max-width: 110px !important;
}

.list-unstyled li {
  padding: 4px
}

.item-price {
  color: #7367f0;
}

.warning {
  color: $warning;
}

.danger {
  color: $danger;
}

.variant-block {
  border-radius: 20px;
  box-shadow: 0 0 40px rgba(0, 0, 0, .15);
}

.feather-info {
  color: #7367f0;
  cursor: pointer;
}

.size-popover {
  max-width: 500px;
}
</style>
